export const languageHashValues= {
  "GLOBAL": ".c6eb7b9691ce5e078213",
  "U4302_TRANSACTIONSDETAIL": ".770fa2e784fd35ab3c3f",
  "U4400_PAYMENT": ".c367f697a2dcb54d71cc",
  "U5122_INVOICEMODIFICATIONAPPROVAL": ".f0e294b7572db68f334d",
  "U2117_PADAGREEMENTINVITATION": ".c813cbbc8738fc2cbed5",
  "U2000_PAD": ".dfc270feab5485dcac15",
  "U4402_INVOICEPORTALPAYMENT": ".d8fbc283220d401f9ec8",
  "U2000_BANKACCOUNTCREATION": ".f3ab8af01641945cc07d",
  "U2011_LOGIN": ".ae6c03e73014d5decdba",
  "U2012_LOGINHELP": ".d768d3c00522914b3323",
  "U2014_SECURITY": ".0734bb27ecf929554404",
  "U2086_EFTPENDINGPAYMENTS": ".64e2eb1115aace027b41",
  "U2087_EFTPAYMENTSNOTIFICATIONS": ".ee1a04af569979ae9082",
  "U2110_ACCOUNTCREATION": ".bae6fc3076fa80153416",
  "U2121_INDIVIDUALPROFILE": ".4957ac18b8628a44ee84",
  "U2131_ORGANIZATIONPROFILE": ".9dc786bc3ce5751afeff",
  "U2112_ERPBINDING": ".4ae5db6e3c1f8d4255dd",
  "U2113_SUPPLIERINVITATION": ".504ceaf72dc7dc297be5",
  "U2118_RBBINDING": ".55d5abd8d205c2884c63",
  "U2132_AFFILIATION": ".9482387532afe45ac911",
  "U2133_BUSINESSLINKS": ".02c306b9581ba5ae008b",
  "U2181": ".51ca90b4af761b5e84a4",
  "U2211_SUBSCRIPTIONOFFER": ".890693bf7ae7a63bfc10",
  "U2212_SUBSCRIPTIONFILTER": ".a1548316ed3a5075a179",
  "U2213_SUBSCRIPTION": ".2ba3fd6c2292fdfea1a5",
  "U4111_CREDITCARDADDITION": ".0524e1fb07b0e62f0182",
  "U4112_CREDITCARDDETAIL": ".112c68fe07c4c8cd4ac7",
  "U4121_BANKACCOUNTADDITION": ".84ce255becf7bc5caa1b",
  "U4122_BANKACCOUNTDETAIL": ".02103b3c6f8fa8c89026",
  "U4303_ANONYMOUSTRANSACTION": ".0f76c4e7b37ae3488962",
  "U4401_PAYMENTGATEWAY": ".10a6893bc326471d3624",
  "U4481_PROCESSINVOICEINSTALMENTPAYMENTS": ".2bf061aa9fcb6c854893",
  "U5000_RECEIVABLE": ".4abeb1444ea56755ea53",
  "U5101_STATEMENTOFACCOUNT": ".fa5cf8e0920e5c170204",
  "U5102_INVOICE": ".ef831f7bde482d1182a5",
  "U5103_PADAGREEMENT": ".e77fd7521015996f7ac1",
  "U5121_SENTINVOICE": ".62e450ef39f7d750eb80",
  "U5124_PGRECEIVEDPAYMENT": ".7dc1c5241db71aa26ed4",
  "U5120_INVOICES": ".f15834080135ba24db51",
  "U5123_PADAGREEMENTCONSOLE": ".f4ada7556bcfcf1c39c4",
  "U5132_PAYMENTRECONCILIATION": ".9bbf37ac93f8fb333257",
  "U5133_BANKRECONCILIATION": ".311b68f053a94a638b18",
  "U6112_BUSINESSLINK": ".79b1c2051b23352d05d2",
  "U6113_SUPPLIERRECORD": ".d73d3b7cf06e41eb1f4d",
  "U6211_PAYLOT": ".03f2d51d897498f635a1",
  "U6311_PURCHASE": ".04438da6b36a72c602bd",
  "U2115_AFFILIATIONINVITATION": ".ed1a5c1b38a28e60c59f",
  "U6100_PAYABLE": ".ebb6a29b762fc1d1ae1c",
  "U6131_SENTPAYMENT": ".0fcdc66bde140f4c159e",
  "U6191_SENTPAYMENTREPORT": ".cfd9263158e318abf8e2",
  "U6221_SENTPAYMENT": ".19cff6c3ea47fc0e84e5",
  "U6422_INTERACSENTPAYMENT": ".287d9538f17268e564fb",
  "U6531_TRANSACTIONPAYMENT": ".5c57c88bd61d6aa52e14",
  "U5131_RECEIVEDPAYMENT": ".8f55fdc626bdf626c10c",
  "U5191_RECEIVEDPAYMENTREPORT": ".e1b899b56c0dce46e923",
  "U2001_HOME": ".134672f8a0760e0741cd",
  "U2024_REPORTLOG": ".523cb42c046599c36233",
  "U2111_INVOICEPORTALBINDING": ".ae53e4f846b7da160dde",
  "U2116_ANONYMOUSSUPPLIERINVITATION": ".20ede827f0f73f54d4db",
  "U6111_SUPPLIERINVITATIONCONSOLE": ".d02b43206978caf205cf",
  "U6511_TRANSACTIONPARTY": ".11bc3734ca826df949bc",
  "U6212_PAYROLLPARTY": ".d9d7f2c9763f6aafd5a8",
  "U6121_EFTLOT": ".915e56340d79ec25cf5c",
  "U6412_INTERACFILE": ".99617fc7ed17b76d9222",
  "U6122_EFTAPPROVAL": ".8b1b7ced79b91c792e6b",
  "U6512_TRANSACTIONLOT": ".543e73dacbfad9886c60",
  "U6123_EFTAPPROVAL": ".566d611137c2552d89e3",
  "U6123_EFTAPPROVALMOBILE": ".b6db1ee9809f5d17e0f4",
  "U2012_LOGIN": ".509f2d02cfc9d32200a5",
  "U2000_PAYMENT": ".780e1e0304b180776719",
  "U4101_CREDITCARDBANKACCOUNTSUMMARY": ".60abf6df349f2c94b95e",
  "U2000_PAYMENTMODEUPDATE": ".783125cec896794d87dc",
  "U6114_TELUSBILLER": ".b7418f8173804968f5dc",
  "U4403_CREDITCARDTERMINAL": ".59320e8c0c22a92142ea",
  "U4404_INTERACTERMINAL": ".eef8ce9b83cbed259c89",
  "U2000_SAMPLECHEQUESELECTION": ".f52a3f4153a141b8af49",
  "U2023_TUTORIALVIDEO": ".f224c95edbbd8fee7e53",
  "U2134_ORGANIZATIONBILLING": ".883e55ece9373bf4bcf4",
  "U6414_BANKACCOUNTWALLET": ".115ccdae47fe24398b8f",
  "U2022_INSTANTACCOUNTVERIFICATION": ".065f4a5029008a257edc",
  "U6532_PENDINGTRANSACTION": ".15b2a7a2e879a3a5bd29",
  "U4301_TRANSACTIONSSUMMARY": ".efdc429963d19d7a764d",
  "U5111_SENTINVOICESTRACKING": ".14fc7a97ec3d8ceec88b",
  "U2000_ACCOUNTPERSONNALINFORMATIONS": ".bc9dd1756a0b72d5861f",
  "U2000_INTRO": ".4b6aed4657865f09614c",
  "U2000_ELECTRONICSIGNATURE": ".ab7093426461562cc148",
  "U2000_VERIFYEMAIL": ".a3be8b327ad99529c7ea",
  "U2200_SUBSCRIPTION": ".782c15adecf79a360475",
  "U2000_VERIFYIDENTITY": ".a7f92383494c9bdd0ca2",
  "U2000_ATTACHMENTVIEWER": ".9b184af66501b19882fe",
  "U2212_PLANLIST": ".f488e544e91a99a491e4",
  "U3002_TAXBILLSEARCHRESULT": ".23fbdf9f7cda335bddb7"
}